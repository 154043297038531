<template>
    <div v-if="loading" class="setOpacity">
        <div style="" class="lottieCss">
            <lottie-animation class="lottieImage"
                ref="anim"
                :animationData="require('@/assets/loader.json')"
                :loop="true"
                :autoPlay="true"
            />
        </div>
    </div>
</template>

<script>

    import LottieAnimation from 'lottie-web-vue'
    export default {
        props: {
            loading: {
                type: Boolean,
                required: true,
            },
        },
        components : {
            LottieAnimation
        },
    };
</script>

<style>

    .setOpacity {
       
    }
    .lottieCss {
        margin: 0 !important;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 500000;
    }
    .lottieImage {
        position: relative;
        height: 200px;
    }
</style>